const burgers = [
    { id:'uru-burger-1', category: 1, img: '/burger/acelga.jpg', name: 'espinaca', description: 'Espinacas crudas, garbanzos, avena laminada, cebolla morada, chía y zanahorias crudas. Condimentos: sal de mar y pimienta negra.', price: 60 },
    { id:'uru-burger-2', category: 1, img: '/burger/arveja.jpg', name: 'arveja', description: 'Arveja, avena laminada, cebolla morada, chía y zanahorias crudas. Condimentos: sal de mar, tomillo, jengibre y limón.', price: 60 },
    { id:'uru-burger-3', category: 1, img: '/burger/garbanzo.jpg', name: 'garbanzo', description: 'Garbanzos, avena laminada, cebolla morada, chía y zanahorias crudas. Condimentos: sal de mar y perejil fresco.', price: 60 },
    { id:'uru-burger-4', category: 1, img: '/burger/legumbres-al-curry.jpg', name: 'legumbres al curry', description: 'Garbanzos, porotos negros, arroz integral, cebolla, chía y zanahorias crudas. Condimentos: curry, ajo, jengibre, sal de mar y albahaca limón.', price: 60 },
    { id:'uru-burger-5', category: 1, img: '/burger/lenteja.jpg', name: 'lenteja', description: 'Lentejas, avena laminada, cebolla, chía y zanahorias crudas. Condimentos: sal de mar, pimienta negra, perejil y orégano frescos.', price: 60 },
    { id:'uru-burger-6', category: 1, img: '/burger/poroto.jpg', name: 'poroto', description: 'Poroto blanco, arroz integral, avena, cebolla rehogada, zanahoria y chía. Condimentos: sal, pimentón, albahaca, ajo, perejil, orégano y limón.', price: 60 },
    { id:'uru-burger-7', category: 1, img: '/burger/remolacha.jpg', name: 'remolacha', description: 'Remolacha, garbanzos, arroz integral, cebolla caramelizada y chía. Condimentos: salvia fresca y azúcar mascabo.', price: 60 },
    { id:'uru-burger-8', category: 1, img: '/burger/zapallo.jpg', name: 'calabaza', description: 'Calabaza, garbanzos, avena laminada, cebolla, chía y zanahorias crudas. Condimentos: sal de mar, cúrcuma, pimienta negra, perejil y salvia fresca.', price: 60 },
    { id:'uru-burger-9', category: 1, img: '/burger/quinoa-y-puerro.jpg', name: 'quinoa y puerro', description: 'Quinoa, puerro, arroz integral, garbanzos, cebolla, chía y zanahorias crudas. Condimentos: sal de mar, pimienta negra y perejil fresco.', price: 60 },
    { id:'uru-burger-10', category: 1, img: '/burger/lenteja.jpg', name: 'lenteja y tomate seco', description: 'Lentejas, tomates secos, avena laminada, chía y cebolla morada. Condimentos: sal de mar y romero fresco.', price: 60 },
];

const dressings = [
    { id:'uru-dressing-1', category: 2, img: '/dressing/garbanzo.jpeg', name: 'garbanzo', description: 'Garbanzo, aceite de girasol. Condimentos: sal, limón, ajo, perejil y pimentón.', price: 150 },
    { id:'uru-dressing-2', category: 2, img: '/dressing/lenteja.jpeg', name: 'lenteja', description: 'Lenteja, aceite de girasol. Condimentos: sal, limón, ajo, perejil y pimentón.', price: 150 },
    { id:'uru-dressing-3', category: 2, img: '/dressing/zanahoria.jpeg', name: 'zanahoria', description: 'Zanahoria, aceite de girasol. Condimentos: sal, limón, ajo y perejil.', price: 150 },
    { id:'uru-dressing-4', category: 2, img: '/dressing/remolacha.jpeg', name: 'remolacha', description: 'Remolacha, aceite de girasol. Condimentos: sal, limón, ajo y perejil.', price: 150 },
];

export const products_uru = [
    ...burgers,
    ...dressings
];